var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{staticStyle:{"overflow":"auto"},attrs:{"visible":_vm.visible,"title":'Danh sách file',"width":"500","destroy-on-close":false,"mask-closable":false},on:{"close":_vm.closeForm}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"xs":24,"md":24,"lg":24}},[_c('a-table',{staticClass:"ant-table-bordered",attrs:{"columns":_vm.columnsListFile,"data-source":_vm.data,"rowKey":(rowKey, index ) => index,"pagination":false,"loading":_vm.loading,"scroll":{ x: '100%' },"locale":{ emptyText: 'Chưa có dữ liệu' }},scopedSlots:_vm._u([{key:"rowIndex",fn:function(text, record, index){return [_c('span',[_vm._v(_vm._s(index + 1)+" ")])]}},{key:"fileName",fn:function(text, record){return [_c('a',{on:{"click":function($event){return _vm.detailFile(record)}}},[_vm._v(_vm._s(record.fileName)+" ")])]}}])})],1)],1)],1)],1),_c('div',{style:({
      position: 'absolute',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e8e8e8',
      padding: '10px 16px',
      textAlign: 'right',
      left: 0,
      background: '#fff',
      borderRadius: '0 0 4px 4px',
    })},[_c('a-button',{on:{"click":_vm.closeForm}},[_vm._v(" Đóng ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }